/*
@contract seoLinksSection {
  anchor-text {
    color: Color;
    color-hover: Color;
    color-active: Color;
  }
}
*/

.seoLinksSection {
  display: flex;
  flex-direction: column;
  justify-content: left;
  margin-top: var(--space-32x);
  margin-bottom: var(--space-16x);
  padding: var(--container-padding);
  --anchor-text-color-default: var(
    --home-page-seo-links-section-anchor-text-color
  );
  --anchor-text-color-hover: var(
    --home-page-seo-links-section-anchor-text-color-hover
  );
  --anchor-text-color-active: var(
    --home-page-seo-links-section-anchor-text-color-active
  );
}

.heading {
  margin-bottom: var(--space-8x);
}
