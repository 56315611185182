.blogSection {
  --heading-margin: 0 0 var(--space-6x);
  margin-top: var(--space-28x);

  @media (--screen-lg) {
    margin-top: var(--space-40x);
  }
}

.blogContainer {
  @media (--screen-lg) {
    --button-font-size: var(--font-size-lg);
    display: flex;
    align-items: stretch;
  }
}

.leftSection {
  margin-bottom: var(--space-8x);

  @media (--screen-lg) {
    display: flex;
    margin-bottom: 0;
    width: 50%;
  }
}

.dottedBackground {
  padding: var(--space-6x);
  background-image: radial-gradient(rgb(34 56 156 / 100%) 12%, transparent 12%),
    radial-gradient(rgb(34 56 156 / 100%) 12%, transparent 12%);
  background-color: var(--color-black);
  background-position: 5px 5px, 17px 18px;
  background-size: 24px 26px;
}

.backgroundLeft {
  display: flex;
  /* stylelint-disable-next-line declaration-property-value-disallowed-list */
  padding: 30px var(--space-4x);
  color: var(--color-white);
  align-items: center;
  width: 100%;

  @media (--screen-xs) {
    /* stylelint-disable-next-line declaration-property-value-disallowed-list */
    padding: var(--space-10x) 30px;
  }

  @media (--screen-sm) {
    /* stylelint-disable-next-line declaration-property-value-disallowed-list */
    padding: 75px 55px;
  }

  @media (--screen-lg) {
    padding: 0;
    justify-content: center;
  }
}

.leftTextSection {
  @media (--screen-lg) {
    width: 268px;
  }

  @media (--screen-2xl) {
    width: 308px;
  }
}

.headingHighlightWord {
  color: var(--color-indigo-400);
}

.blogSubHeading,
.blogCopy {
  margin-bottom: var(--space-6x);
}

.rightSection {
  display: flex;
  overflow: auto;
  margin-top: -4px;
  margin-left: -4px;
  padding: var(--space-1x);

  @media (--screen-lg) {
    padding-left: var(--space-20x);
    display: block;
    width: 50%;
  }

  @media (--screen-2xl) {
    padding-left: var(--space-28x);
  }
}
