.appPromotion {
  --heading-padding: 0 0 var(--space-6x);
  margin-top: var(--space-24x);

  @media (--screen-lg) {
    margin-top: var(--space-40x);
  }
}

.appPromotionContainer {
  @media (--screen-md) {
    display: flex;
    align-items: center;
  }
}

.appDownloadIcons {
  margin-top: var(--space-6x);

  @media (--screen-md) {
    margin-top: 0;
  }
}

.appPromotionLeft {
  @media (--screen-md) {
    width: 50%;
    margin-bottom: 0;
  }
}

.appPromotionImageWrapper {
  position: relative;
  /* stylelint-disable-next-line declaration-property-value-disallowed-list */
  margin-bottom: 25px;
  height: 100%;
  aspect-ratio: 1500/1619;

  @media (--screen-md) {
    margin-bottom: 0;
  }
}

.appPromotionAppImage {
  position: static;
}

.appPromotionFastest {
  color: var(--color-indigo-400);
}

.appPromotionSubheaderWrapper {
  color: var(--color-white);
}

.appPromotionContentWrapper {
  @media (--screen-md) {
    width: 50%;
    /* stylelint-disable-next-line declaration-property-value-disallowed-list */
    padding-left: 75px;
  }

  @media (--screen-lg) {
    /* stylelint-disable-next-line declaration-property-value-disallowed-list */
    padding-left: 100px;
  }

  @media (--screen-2xl) {
    padding-left: var(--space-28x);
  }
}

.appPromotionMobileSubheader {
  margin-bottom: var(--space-6x);

  @media (--screen-md) {
    display: none;
  }
}

.appPromotionLargeSubheader {
  margin-bottom: var(--space-6x);
  display: none;

  @media (--screen-md) {
    display: block;
  }
}

.appPromotionMobileCTA {
  margin-top: var(--space-8x);

  @media (--screen-md) {
    display: none;
  }
}

.appPromotionPlayStore {
  margin-right: var(--space-8x);

  @media (--screen-md) {
    margin-bottom: var(--space-5x);
    display: block;
  }
}

.appPromotionStoreIcons {
  display: inline-block;
  min-width: unset;
  max-height: 31px;
}

.appPromotionLargeCTA {
  display: none;

  @media (--screen-md) {
    display: block;
  }
}

.appPromotionQRWrapper {
  display: flex;
  align-items: center;
  margin-bottom: var(--space-7x);
}

.appPromotionQRIconWrapper {
  margin-right: var(--space-9x);
}

.appPromotionQRCode {
  max-width: 95px;
  width: 95px;
}

.appPromotionScanText {
  font-weight: var(--font-weight-semibold);
  color: var(--color-indigo-400);
}
