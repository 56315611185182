.blogLink {
  /* stylelint-disable-next-line declaration-property-value-disallowed-list */
  margin-right: var(--space-px);

  @media (--screen-lg) {
    margin-bottom: var(--space-12x);
  }
}

.blogLinks {
  --frameable-image-display: none;
  display: block;
  color: var(--color-white);
  text-decoration: none;
  margin-right: var(--space-8x);

  @media (hover: hover) {
    &:hover {
      --frameable-image-display: block;
      text-decoration: underline;
    }
  }

  @media (--screen-md) {
    /* stylelint-disable-next-line declaration-property-value-disallowed-list */
    margin-right: 76px;
  }

  @media (--screen-lg) {
    display: flex;
    margin-right: 0;
    align-items: center;
  }
}

.frameableImage {
  margin-bottom: var(--space-6x);

  @media (--screen-lg) {
    margin-bottom: 0;
    margin-right: var(--space-8x);
  }
}

.linksCopySection {
  @media (--screen-lg) {
    flex: 1;
  }
}

.linksHeader {
  margin-bottom: var(--space-3x);
  font-size: var(--font-size-md);
  font-weight: bolder;

  @media (--screen-md) {
    font-size: var(--font-size-lg);
  }
}

.linksCopy {
  font-size: var(--font-size-md);

  @media (--screen-lg) {
    font-size: var(--font-size-lg);
  }
}
