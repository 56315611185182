.wrapper {
  position: relative;
  width: 100%;
  height: 287px;

  --search-combobox-list-wrap-box-shadow: -1px 0 0 0 var(--color-gray-200),
    1px 0 0 0 var(--color-gray-200), 0 1px 0 0 var(--color-gray-200),
    0 -1px 0 0 var(--color-gray-200);
  --search-combobox-list-wrap-visible-border-radius: 0.25rem;
  --search-combobox-search-button-font-size: var(--font-size-body);

  @media (--screen-md) {
    height: 347px;
  }

  @media (--screen-lg) {
    --search-combobox-search-button-font-size: var(--font-size-lg);
    height: 339px;
  }
}

.heroImageWrapper {
  position: absolute;
  top: 0;
  width: 100%;
  height: 305px;

  @media (--screen-md) {
    height: 365px;
  }
}

.heroImageWrapper::before {
  content: '';
  z-index: var(--z-index-1x);
  width: 100%;
  height: 100%;
  display: block;
  position: absolute;
  background: linear-gradient(
    90deg,
    rgb(0 0 0 / 20%) 27.61%,
    rgb(23 15 1 / 0%) 100%
  );
}

.headingText {
  margin: 0 auto var(--space-3x);
  letter-spacing: -0.02em;
  color: var(--color-white);
  font-weight: normal;
  font-size: var(--font-size-5xl);
  line-height: 41px;
  text-shadow: 0 0 15px rgb(0 0 0 / 80%);

  @media (--screen-md) {
    margin: 0 auto var(--space-5x);
  }

  @media (--screen-lg) {
    margin: 0 auto var(--space-7x);
    letter-spacing: -0.03em;
    font-size: var(--font-size-6xl);
  }
}

.headingTextWordWrap {
  @media (--screen-sm) {
    display: none;
  }
}

.headingTextWrapper {
  position: relative;
  max-width: 1500px;
  text-align: center;
  margin: 0 auto;
  padding: 35px var(--space-6x) 0;
  z-index: var(--z-index-2x);

  @media (--screen-md) {
    padding: 70px var(--space-6x) 0;
  }

  @media (--screen-lg) {
    padding: 80px var(--space-6x) 0;
  }
}

.form {
  --search-combobox-list-wrap-left: 0;
  margin: 0 auto;
  max-width: 502px;

  .searchInput {
    height: 62px;
  }

  .searchResults {
    position: absolute;
    transform: translateY(var(--space-2x));
    top: 72px;

    @media (--screen-md) {
      max-height: 55vh;
    }
  }

  .searchButton {
    padding: 0 var(--space-4x);
  }

  .searchResultHeader:first-child {
    border: 0;
  }
}

.comboboxSkeleton {
  width: 502px;
  height: 62px;
  margin: 0 auto;
  background: var(--color-gray-200);
}
